.services {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  background: url("../../assets/images/Third\ Image.png")
    no-repeat center center;
  color: white;
  display: flex;
  background-size: cover;
  scroll-snap-align: start;
  align-items: center;
  overflow: hidden;
}

.services .container {
  max-width: 1200px;
  padding: 50px 20px;
  margin: 30px auto;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.services h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
}

.services .subtitle {
  text-align: center;
  margin-bottom: 50px;
  font-size: 1.1rem;
}

.services-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: right;
  width: 60%;
  margin-left: auto;
  margin-right: 40px;
}

.service-card {
  background: white;
  padding: 30px;
  border-radius: 2px;
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card h4 {
  font-size: 1.3rem;
  margin-bottom: 10px;
  color: black;
}

.service-card p {
  line-height: 1.6;
  font-size: 0.7rem;
  color: black;
}

@media (max-width: 992px) {

  .services{
    max-height: 130vh;
  }
  
  .services-grid {
    width: 80%;
    margin-right: 20px;
  }

}

@media (max-width: 768px) {
  
  .services{
    height: 130vh;
  }
  .services-grid {
    grid-template-columns: 1fr;
    width: 90%;
    margin: 0 auto;
    margin-top: -20px;
  }

  .service-card h4{
    font-size: 1rem;
  }
  .service-card p {
    font-size: 0.6rem;
  }

  .services h2 {
    font-size: 2rem;
  }
}

