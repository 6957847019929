.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .scroll-btn {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .scroll-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0,0,0,0.4);
  }
  