.hero {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  background: url('../../assets/images/First image.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  text-align: left;
  perspective: 1000px;
  scroll-snap-align: start;
}

.hero-content {
  max-width: 1200px;
  width: 100%;
  padding: 0 10%;
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
}

.hero-content:hover {
  transform: translateZ(20px);
}

.hero h1 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
  line-height: 1.2;
  /* Width remains unchanged */
}

/* Your original highlight and outline styling */
.hero .highlight {
  color: #ffffff;
  font-weight: 800;
}

.hero .outline-text {
  color: transparent;
  -webkit-text-stroke: 1px white;
  font-weight: 100;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.services-preview {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 40px;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
}

.service-item:hover {
  transform: rotateY(10deg) scale(1.05);
}

.service-number {
  height: 70px;
  width: 70px;
}

.service-text {
  font-weight: 500;
  color: white;
  position: relative;
  z-index: 2;
}

@media (max-width: 768px) {
  .hero {
    height: auto;
    padding-bottom: 80px;
  }

  .hero-content {
    text-align: center;
    padding: 10rem 5%;
  }

  .hero h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .services-preview {
    position: static;
    flex-direction: column;
    align-items: center;
    margin-top: -80px;
    gap: 20px;
  }

  .service-item {
    align-items: center;
  }

  .service-number {
    height: 50px;
    width: 50px;
  }

  .service-text {
    font-size: 1rem;
  }
}


