.contact {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  background: url("../../assets/images/Sixth\ image.png") no-repeat center center;
  background-size: cover;
  color: white;
  scroll-snap-align: start;
}

.contact .container {
  max-width: 1200px;
  width: 100%;
  padding: 0 5%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact h2 {
  margin-top: 20px;
  font-size: 2.3rem;
  text-align: center;
}

.contact .subtitle {
  text-align: center;
  margin-bottom: 100px;
  font-size: 1rem;
}

.contact-content {
  display: flex;
  gap: 50px;
}

.contact-form {
  flex: 2;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.form-group textarea {
  height: 150px;
  resize: none;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

button {
  background: white;
  color: #000;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  transition: transform 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
}

.contact-info {
  flex: 1;
}

.info-item {
  margin-bottom: 20px;
}

.info-item h3 {
  font-size: 1rem;
  margin-bottom: 10px;
  color: white;
}

.info-item p {
  font-size: 0.9rem;
}

.social-icons {
  flex: 0 0 80px;
  text-align: center;
  padding-bottom: 10px;
}

.social-icons a {
  margin: 0 10px;
  display: inline-block;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.social-icons img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.social-icons a:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

@media (max-width: 768px) {
  .contact {
    height: auto;
    max-height: 200vh;
    padding: 0 0;
  }

  .contact-content {
    flex-direction: column;
    gap: 30px;
  }

  .contact h2 {
    font-size: 2rem;
  }
}