.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 1rem 2rem;
  background: transparent;
  perspective: 1000px; /* Enable 3D effects */
  transition: all 0.3s ease;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-left {
  flex: 1;
}

.logo {
  height: 40px;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.contact-us {
  color: white;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.contact-us:hover {
  color: #ffd700;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 18px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.hamburger:hover {
  transform: scale(1.1);
}

.hamburger .line {
  height: 3px;
  width: 100%;
  background-color: white;
  transition: all 0.3s ease;
  transform-origin: center;
}

/* Menu styles with 3D fade-in transition */
.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 2rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  animation: fadeInMenu 0.4s ease forwards;
  transform: perspective(600px) rotateX(10deg);
}

@keyframes fadeInMenu {
  from {
    opacity: 0;
    transform: perspective(600px) rotateX(-15deg);
  }
  to {
    opacity: 1;
    transform: perspective(600px) rotateX(0deg);
  }
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.menu ul li:last-child {
  margin-bottom: 0;
}

.menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.menu ul li a:hover {
  color: #ffd700;
}

.menu ul li:hover {
  transform: translateX(5px);
}

.contact-mobile{
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-container {
    padding: 1rem;
  }
  .contact-us {
    display: none;
  }
  .contact-mobile{
    display: inline;
  }
  .menu {
    transform: translateX(50%) perspective(600px) rotateX(0deg);
  }
}
