.reviews {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  background: url("../../assets/images/Fifth image.png") no-repeat;
  color: white;
  background-color: #611a3f;
  background-size: 110%;
  scroll-snap-align: start;
  align-items: center;
  overflow: hidden;
}

.top-title {
  margin-top: 30px;
}

.subtitle {
  margin-top: 3px;
  font-size: 1rem;
}

.reviews .container {
  max-width: 1200px;
  width: 100%;
  padding: -30px 5%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reviews h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
}

.reviews .subtitle {
  text-align: center;
  margin-bottom: 50px;
  font-size: 1rem;
}

.reviews-content {
  display: flex;
  justify-content: right;
  width: 40%;
  margin-left: auto;
  margin-right: 60px;
  margin-top: 50px;
}

.review-card {
  flex: 1;
  padding: 30px;
}

.review-text {
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.review-text img {
  height: 15%;
  width: 10%;
}

.reviewer h4 {
  font-size: 1.2rem;
  margin-bottom: 1px;
}

.reviewer p {
  font-size: 0.8rem;
}

/* 3D Cube Progress Indicator Styling */
.cube-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  perspective: 1000px;
}

.cube {
  width: 10px;
  height: 10px;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  border-radius: 100px;
  transform-style: preserve-3d;
  transition: transform 0.6s, background 0.6s;
}

.cube.active {
  transform: rotateY(180deg) scale(1.2);
  background: linear-gradient(145deg, #ff4081, #f50057);
}

@media (max-width: 992px) {
  .reviews {
    background: none;
    background-color: #611a3f;
  }
  .reviews-content {
    width: 60%;
    margin-right: 30px;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .reviews {
    height: auto;
    min-height: 100vh;
    padding: 0;
    background: none;
    background-color: #611a3f;
  }
  .reviews-content {
    width: 90%;
    margin: 30px auto;
  }
  .reviews h2 {
    margin-top: 0;
  }
}
