.vision {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  padding: 0;
  background: url("../../assets/images/Fourth\ image.png")
    no-repeat center center;
  color: white;
  background-color: #0b8a99;
  background-size: cover;
  scroll-snap-align: start;
  align-items: center;
  overflow: hidden;
}

.vision .container {
  max-width: 1200px;
  width: 100%;
  padding: 60px 5%;
  margin: 0 auto;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.vision h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
}

.vision .subtitle {
  text-align: center;
  margin-bottom: 50px;
  font-size: 1.1rem;
}

.vision-content {
  display: flex;
  gap: 50px;
  align-items: center;
}

.vision-items {
  flex: 1;
}

.vision-item {
  margin-bottom: 40px;
  width: 60%;
}

.vision-item h3 {
  font-size: 1rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.vision-item h3:before {
  content: "•";
  margin-right: 10px;
  color: #ffd700;
}

.vision-item p {
  margin-left: 20px;
  line-height: 1.6;
  font-size: 0.9rem;
}

.vision-image {
  flex: 1;
}

.vision-image img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .vision {
    height: auto;
    max-height: 110vh;
    padding: 0 0;
  }

  .vision-content {
    flex-direction: column;
  }

  .vision-item {
    width: 100%;
  }

  .vision h2 {
    font-size: 1.8rem;
  }
  .vision p{
    font-size: 0.8rem;
  }
}

