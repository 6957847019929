@font-face {
  font-family: 'SofiaProLight';
  src: url('/public/fonts/sofiapro-light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
}

body {
  font-family: 'SofiaProLight', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  position: relative;
  width: 100%;
  overflow: hidden;
}

section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.container {
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }
  
  .services-preview {
    flex-direction: column;
  }
}