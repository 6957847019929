
.about {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  background: url('../../assets/images/Second image.png') no-repeat center center;
  background-size: cover;
  color: white;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  align-items: center;
  overflow: hidden;
}

.about .container {
  max-width: 1200px;
  width: 100%;
  padding: 50px 5%;
}

.about h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.subtitle {
  text-align: center;
  margin-bottom: 60px;
  font-size: 1rem;
}

.about-content {
  display: flex;
  gap: 20px;
  align-items: center;
}

.about-image {
  justify-content: center;
  align-items: center;
  transition: transform 0.6s ease;
  margin-top: -40px;
}

.about-image img {
  width: 70%;
  height: 70%;
}

.about-image img:hover {
  transform: scale(1.05) rotateY(5deg);
}

.about-text {
  flex: 1;
  animation: fadeInUp 1s ease-out;
  margin-top: 20px;
}

.about-text h3 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  transition: color 0.4s ease;
}

.about-text h3:hover {
  color: #FFD700;
}

.about-text p {
  margin-bottom: 30px;
  line-height: 1.6;
  font-size: 0.9rem;
}

.about-text ul {
  list-style: none;
  padding: 0;
}

.about-text ul li {
  margin-bottom: 15px;
  padding-left: 35px;
  position: relative;
  font-size: 0.9rem;
  transition: transform 0.3s ease;
}

.about-text ul li:hover {
  transform: translateX(5px);
}

.about-text ul li:before {
  content: "✓";
  position: absolute;
  left: 0;
  top: 0;
  color: #FFD700;
  font-size: 1rem;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {

  .about-content {
    flex-direction: column;
    text-align: center;
  }
  
  .about-image {
    display: none;
  }
  
  .about h2 {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 0.8rem;
    margin-bottom: 40px;
  }
  
  .about-text {
    width: 100%;
    padding: 0 15px;
  }
  
  .about-text h3 {
    font-size: 1.2rem;
  }
  
  .about-text p,
  .about-text ul li {
    font-size: 0.8rem;
  }
}
